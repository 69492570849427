.main-sticky-wrapper {
  height: 100%;
  background: rgba(247, 224, 52, 97);
  border-radius: 15px;
  resize: both;
  overflow: hidden;
}

.sticky-menu {
  width: 100%;
  height: 30px;
  background: rgba(237, 196, 50, 93);
  border-radius: 15px 15px 0px 0px;
  position: absolute;
  top: 0;
}

.sticky-content {
  flex-grow: 1;
  outline: 0;
  cursor: text;
  height: 100%;
  padding: 10px;
  padding-top: 40px;
  overflow: auto;
  scrollbar-color: red yellow;
  background: transparent;
  border: 0;
  display: block;
  width: 100%;
  resize: none;
  box-sizing: border-box;
}

.menu-icon {
  width: 15px;
  margin: 6px;
}

.sticky-content::-webkit-scrollbar-track {
  background: red;
}
.sticky-content::-webkit-scrollbar-thumb {
  background: red;
}
