.background {
  width: 100%;
}

* {
  padding: 0;
  margin: 0;
}

.App {
  background: url(../assets/background.svg);
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  position: relative;
}

.testmain {
  width: 500px;
  height: 500px;
  background: blue;
}

.App > * {
  z-index: 2;
  position: relative;
}
